import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Modal, IconButton, Divider, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import isMobile from '../../hooks/isMobile';
import { SocketContext } from '../../utils/SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  getMessageAction,
  pinMessageAction,
  removePinMessageAction,
} from '../../store/message/messageActions';
import { UserDataContext } from '../../routes/Main';
import { MenuOption, MessageData, RoomData } from '../../store/constants/constants';
import { setSelectedMessage } from '../../store/message/messageSlice';
import OptionsMenu from './ContextMenu/OptionsMenu';
import { debounce } from 'lodash';

interface ChatAreaProps {
  chat: RoomData | null;
}

const ChatArea: React.FC<ChatAreaProps> = ({ chat }) => {
  const mobileView = isMobile();
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const pinnedMessageRef = useRef<HTMLDivElement | null>(null);

  // Scroll to bottom of chat when component first mounts or when messages change
  // useEffect(() => {
  //   scrollToBottom();
  // }, []);

  // Scroll to bottom of chat whenever 'messages' array changes
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // const scrollToBottom = () => {
  //   if (chatEndRef.current) {
  //     chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  // const handleFileClick = async (file: File) => {
  //   if (file.type.startsWith('image/')) {
  //     setSelectedFile(URL.createObjectURL(file));
  //   } else {
  //     const text = await file.text();
  //     setSelectedFile(text);
  //   }
  //   setOpen(true);
  // };

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { userData } = useContext(UserDataContext);

  const message1 = {
    roomId: chat?.id,
    userId: userData?.data?.id,
    page: 1,
    limit: 200,
  };

  const messages = useSelector((state: RootState) => state.message.messages);
  const pinnedMessage = useSelector((state: RootState) => state.message.pinMessage);

  // Debounced function to dispatch the socket request
  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(getMessageAction({ socket, message1 }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket, messages]);

  const handleClose = () => {
    setSelectedFile(null);
    setOpen(false);
  };

  const isFirstMessage = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    return (
      currentMessage.sender !== previousMessage.sender ||
      !isSameDay(currentMessage?.createdAt, previousMessage?.createdAt)
    );
  };

  const isSameDay = (date1?: string, date2?: string) => {
    if (!date1 || !date2) return false;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getDateDisplay = (date?: string) => {
    if (!date) return '';

    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (isSameDay(messageDate.toISOString(), today.toISOString())) {
      return 'Today';
    } else if (isSameDay(messageDate.toISOString(), yesterday.toISOString())) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const shouldShowDateHeader = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    return !isSameDay(currentMessage?.createdAt, previousMessage?.createdAt);
  };

  // Context menu

  // const [tempSelectedMessage, setTempSelectedMessage] = useState<MessageData | null>(null);

  const handleMessageClick = (messageId: number | undefined) => {
    if (messageId !== undefined) {
      setSelectedMessageId((prevId) =>
        prevId === messageId.toString() ? null : messageId.toString(),
      );
    }
  };

  const handlePinMessage = (messageId: number) => {
    if (chat && chat.id && socket) {
      dispatch(pinMessageAction({ socket, pinData: { roomId: chat.id, messageId } }));
    } else {
      console.error('Unable to pin message: chat, chat.id, or socket is undefined');
    }
  };

  const handleRemovePinMessage = (pinnedMessageId: number) => {
    if (socket) {
      dispatch(removePinMessageAction({ socket, pinnedMessageId }));
    } else {
      console.error('Unable to remove pinned message: socket is undefined');
    }
  };

  const handleOptionSelect = (option: MenuOption, message: MessageData) => {
    if (option.action === 'reply') {
      dispatch(setSelectedMessage(message));
    } else if (option.action === 'pin' && message.id !== undefined) {
      handlePinMessage(message.id);
    } else if (option.action === 'unpin' && pinnedMessage?.id !== undefined) {
      handleRemovePinMessage(pinnedMessage?.id);
    }
    setSelectedMessageId(null);
  };

  const scrollToPinnedMessage = () => {
    if (pinnedMessageRef.current) {
      pinnedMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const renderPinnedMessage = () => {
    if (!pinnedMessage) {
      return null; // Return null if there's no pinned message
    }

    return (
      <Box
        sx={{
          bgcolor: '#f0f0f0',
          p: 1,
          mb: 0.5,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={scrollToPinnedMessage}
      >
        <PushPinIcon
          fontSize='medium'
          sx={{ mr: 2, bgcolor: 'white', borderRadius: '100px', p: 0.5 }}
        />
        <Typography variant='body2' sx={{ flexGrow: 1 }}>
          {pinnedMessage.message.content}
        </Typography>
        <IconButton
          size='small'
          onClick={(e) => {
            e.stopPropagation();
            handleRemovePinMessage(pinnedMessage.id);
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box sx={{ py: pinnedMessage ? 0 : 4 }}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        {renderPinnedMessage()}
      </Box>

      {messages && messages.map((message, index) => {
        const isSender = message?.sender?.userId ? message.sender.userId === userData.data.id : true;
        const showAvatar = message?.sender?.userId ? (!isSender && isFirstMessage(index)) : false;
        const isSelected = message.id !== undefined && selectedMessageId === message.id.toString();
        const isPinned = pinnedMessage && pinnedMessage.message.id === message.id;

        // const showTimestamp = isFirstMessage(index);

        // Determine if this message needs to show date header
        const showDateHeader = shouldShowDateHeader(index);

        return (
          <Box key={index}>
            {/* Date header */}
            {showDateHeader && (
              <Box sx={{ textAlign: 'center' }}>
                <Divider variant='middle'>
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'gray',
                      display: 'inline-block',
                      bgcolor: '#fff',
                      px: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    {getDateDisplay(message?.createdAt)}
                  </Typography>
                </Divider>
              </Box>
            )}

            {/* Message content */}
            <Box
              ref={isPinned ? pinnedMessageRef : null}
              display='flex'
              flexDirection={isSender ? 'row-reverse' : 'row'}
              alignItems='center'
              mb={1}
            >
              {showAvatar ? (
                <Avatar
                  alt={message.sender?.userName}
                  src={
                    message.sender?.profilePictureURL ||
                    `https://randomuser.me/api/portraits/lego/${index % 10}.jpg`
                  }
                  sx={{ width: 30, height: 30, mr: isSender ? 0 : 1, ml: isSender ? 1 : 0 }}
                />
              ) : (
                <Box sx={{ width: 30, height: 30, mr: isSender ? 0 : 1, ml: isSender ? 1 : 0 }} />
              )}
              <Box
                sx={{
                  maxWidth: mobileView ? '65%' : '40%',
                  display: 'flex',
                  alignItems: isSender ? 'flex-end' : 'flex-start',
                  flexDirection: 'column',
                }}
              >
                {/* pinned icon on top of the message bubble  */}

                {isPinned && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography fontSize={'small'} sx={{ fontWeight: 'bold' }}>
                      Pinned
                    </Typography>
                    <PushPinIcon
                      fontSize='small'
                      sx={{
                        color: '#555',
                      }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isSender ? 'row' : 'row-reverse',
                    // alignSelf: 'end',
                  }}
                >
                  {/* option popups when clicked in the chat bubble */}

                  {isSelected && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: isSender ? 'auto' : 1,
                        mr: isSender ? 1 : 'auto',
                        flexDirection: isSender ? 'row-reverse' : 'row',
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          fontWeight: '300',
                          color: 'text.secondary',
                          fontSize: '12px',
                          ml: isSender ? 0.25 : 0,
                          mr: isSender ? 0 : 0.25,
                        }}
                      >
                        {message.createdAt
                          ? new Date(message.createdAt).toLocaleTimeString('en-GB', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })
                          : ''}
                      </Typography>
                      <OptionsMenu
                        options={
                          isSender
                            ? isPinned
                              ? [
                                  { label: 'Copy', action: 'copy' },
                                  { label: 'Delete', action: 'delete' },
                                  { label: 'Edit', action: 'edit' },
                                  { label: 'Forward', action: 'forward' },
                                  { label: 'Unpin', action: 'unpin' },
                                  { label: 'Reply', action: 'reply' },
                                ]
                              : [
                                  { label: 'Copy', action: 'copy' },
                                  { label: 'Delete', action: 'delete' },
                                  { label: 'Edit', action: 'edit' },
                                  { label: 'Forward', action: 'forward' },
                                  { label: 'Pin', action: 'pin' },
                                  { label: 'Reply', action: 'reply' },
                                ]
                            : isPinned
                            ? [
                                { label: 'Copy', action: 'copy' },
                                { label: 'Forward', action: 'forward' },
                                { label: 'Unpin', action: 'unpin' },
                                { label: 'Reply', action: 'reply' },
                              ]
                            : [
                                { label: 'Copy', action: 'copy' },
                                { label: 'Forward', action: 'forward' },
                                { label: 'Pin', action: 'pin' },
                                { label: 'Reply', action: 'reply' },
                              ]
                        }
                        onOptionSelect={(option) => handleOptionSelect(option, message)}
                        isSender={isSender}
                      />
                    </Box>
                  )}

                  {/* chat bubble where message in shown */}
                  <Box
                    onClick={() => handleMessageClick(message.id)}
                    sx={{
                      bgcolor: isSender ? '#EAF0FF' : '#D3D3D3',
                      p: '6px 10px',
                      borderRadius: '12px',
                      position: 'relative',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: isSender ? '#DAEAFF' : '#C3C3C3',
                      },
                    }}
                  >
                    {/* senders message */}
                    {message.content && message.type === 'text_messages' && (
                      <Typography sx={{ fontWeight: isPinned ? 'bold' : 'normal' }}>
                        {message.content}
                      </Typography>
                    )}

                    {/* reply message */}
                    {message.content && message.type === 'replyMessage' && (
                      <>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={{
                            mb: 1,
                            p: 1,
                            borderLeft: '4px solid #dbe5e5',
                            bgcolor: '#f5f5f5',
                            borderRadius: '4px',
                          }}
                        >
                          {message.repliedTo?.content}
                        </Typography>
                        <Typography>{message.content}</Typography>
                      </>
                    )}

                    {/* message containing photo, video, and other attachments */}
                    {message.type === 'attachments' && (
                      <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {message.path && /\.(jpg|jpeg|png|gif)$/i.test(message.path) ? ( // Image extensions
                            <img
                              src={`https://chatroom.enitiation.com/${message.path}`} // Image path
                              alt='Attachment'
                              style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                objectFit: 'contain',
                                marginTop: '8px',
                              }}
                            />
                          ) : message.path && /\.(mp4|mov|avi|mkv)$/i.test(message.path) ? ( // Video extensions
                            <video
                              controls
                              style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '8px',
                              }}
                            >
                              <source
                                src={`https://chatroom.enitiation.com/${message.path}`}
                                type='video/mp4'
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : message.path && /\.(pdf)$/i.test(message.path) ? ( // PDF files
                            <>
                              <img
                                src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                                alt='PDF Icon'
                                style={{ width: '30px', height: '30px', marginRight: '8px' }}
                              />
                              <a
                                href={`https://chatroom.enitiation.com/${message.path}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  color: '#007bff',
                                  textDecoration: 'underline',
                                  marginTop: '8px',
                                }}
                              >
                                {message.path.split('/').pop()}
                              </a>
                            </>
                          ) : message.path && /\.(docx|doc)$/i.test(message.path) ? ( // Word documents
                            <>
                              <img
                                src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                                alt='Word Icon'
                                style={{ width: '30px', height: '30px', marginRight: '8px' }}
                              />
                              <a
                                href={`https://chatroom.enitiation.com/${message.path}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  color: '#007bff',
                                  textDecoration: 'underline',
                                  marginTop: '8px',
                                }}
                              >
                                {message.path.split('/').pop()}
                              </a>
                            </>
                          ) : message.path && /\.(txt)$/i.test(message.path) ? ( // Text files
                            <>
                              <img
                                src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                                alt='Word Icon'
                                style={{ width: '30px', height: '30px', marginRight: '8px' }}
                              />
                              <a
                                href={`https://chatroom.enitiation.com/${message.path}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  color: '#007bff',
                                  textDecoration: 'underline',
                                  marginTop: '8px',
                                }}
                              >
                                {message.path.split('/').pop()}
                              </a>
                            </>
                          ) : (
                            <a
                              href={`https://chatroom.enitiation.com/${message.path}`} // Fallback for other file types
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                color: '#007bff',
                                textDecoration: 'underline',
                                marginTop: '8px',
                              }}
                            >
                              Download File
                            </a>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                {(message.type === "chat_logs") && (
                <Box
                  sx={{
                    bgcolor: '#F5F5F5',
                    p: '8px 12px',
                    borderRadius: '5px',
                    mt: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  >
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      {message.content}
                    </Typography>
                  </Typography>
                </Box>
              )}
              </Box>
            </Box>
          </Box>
        );
      })}
      <div ref={chatEndRef} />

      {/* Modal for displaying selected file */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='preview'
        aria-describedby='preview'
        closeAfterTransition
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'relative',
          }}
        >
          {selectedFile && selectedFile.startsWith('data:image') ? (
            <img src={selectedFile} alt='Preview' style={{ maxHeight: '90%', maxWidth: '90%' }} />
          ) : (
            <Box
              sx={{
                bgcolor: 'white',
                p: 1,
                borderRadius: 1,
                boxShadow: 24,
                maxHeight: '80vh',
                overflowY: 'auto',
              }}
            >
              <Typography>{selectedFile}</Typography>
            </Box>
          )}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatArea;
