import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomParticipant, RoomData, RemovedParticipant } from '../constants/constants';

interface RoomState {
  rooms: RoomData[];
  total: number;
  page: number;
  limit: number;
  loading: boolean;
  error: string | null;
}

const initialState: RoomState = {
  rooms: [],
  total: 0,
  page: 1,
  limit: 10,
  loading: false,
  error: null,
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRooms(
      state,
      action: PayloadAction<{ rooms: RoomData[]; total: number; page: number; limit: number }>,
    ) {
      state.rooms = action.payload.rooms;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
    },
    addRoom(state, action: PayloadAction<any>) {
      state.rooms.push(action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    addRoomParticipants(
      state,
      action: PayloadAction<{ roomId: number; participants: RoomParticipant[] }>,
    ) {
      const room = state.rooms.find((room) => room.id === action.payload.roomId);
      if (room) {
        room.roomParticipants.push(...action.payload.participants);
      }
    },

    removeRoomParticipants(
      state,
      action: PayloadAction<{ roomId: number; removedParticipants: RemovedParticipant[] }>,
    ) {
      const room = state.rooms.find((room) => room.id === action.payload.roomId);
      if (room && room.roomParticipants) {
        room.roomParticipants = room.roomParticipants.filter(
          (participant) =>
            !action.payload.removedParticipants.some(
              (removedParticipant) => removedParticipant.id === participant.id,
            ),
        );
      }
    },
  },
});

export const {
  setRooms,
  setLoading,
  setError,
  addRoom,
  addRoomParticipants,
  removeRoomParticipants,
} = roomSlice.actions;

export default roomSlice.reducer;
