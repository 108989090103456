import { createAsyncThunk } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import {
  setRooms,
  setLoading,
  setError,
  addRoom,
  addRoomParticipants,
  removeRoomParticipants,
} from './roomSlice';
import {
  CreateRoomPayload,
  RoomData,
  AddParticipantPayload,
  UserData,
  RoomParticipant,
  RemoveParticipantPayload,
} from '../constants/constants';
import { RootState } from '../../store/store';
import { fetchUser } from '../user/userActions';

interface FetchRoomsByUserArgs {
  socket: Socket;
  userId: number;
}

interface CreateRoomArgs {
  socket: Socket;
  payload: CreateRoomPayload;
}

interface AddParticipantsArgs {
  socket: Socket;
  payload: AddParticipantPayload;
}

interface RemoveParticipantsArgs {
  socket: Socket;
  payload: RemoveParticipantPayload;
}

export const fetchRoomsByUser = createAsyncThunk(
  'room/fetchRoomsByUser',
  async ({ socket, userId }: FetchRoomsByUserArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      if (socket) {
        socket.emit('getRoomByUser', { userId: userId, page: 1, limit:100 });

        // Consider using `on` if you expect multiple responses or switch back to `once` if it's one-time
        socket.on('getRoomByUsers', (response: any) => {
          if (response.error) {
            dispatch(setError(response.error));
          } else {
            const { data, total, page, limit } = response;
            dispatch(setRooms({ rooms: data, total, page, limit }));
          }
          dispatch(setLoading(false));
        });
      }
    } catch (error) {
      console.error('Error in fetching rooms by userId:', error);
      dispatch(setError('Failed to fetch rooms'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const createRoom = createAsyncThunk(
  'room/createRoom',
  async ({ socket, payload }: CreateRoomArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      socket.emit('joinRoom', payload);

      socket.once('roomJoined', (response: RoomData) => {
        if (response) {
          dispatch(addRoom(response));
        } else {
          dispatch(setError('Failed to create room'));
        }
        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error('Error in creating room:', error);
      dispatch(setError('Failed to create room'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const addParticipants = createAsyncThunk<void, AddParticipantsArgs, { state: RootState }>(
  'room/addParticipants',
  async ({ socket, payload }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      socket.emit('addRoomParticipants', payload);

      socket.once('addRoomParticipant', async (response: string) => {
        const match = response.match(/Users with id: (\d+) added to Room with id: (\d+)/);
        if (match) {
          const [, userIds, roomId] = match;
          const addedUserIds = userIds.split(',').map(Number);
          console.log('hi');
          const addedParticipants: RoomParticipant[] = [];
          for (const userId of addedUserIds) {
            await dispatch(fetchUser({ socket, id: userId }));
            const state = getState();
            const userData = state.user.users.find((user: UserData) => user.userId === userId);

            if (userData) {
              const existingParticipant = state.room.rooms
                .find((room: RoomData) => room.id === Number(roomId))
                ?.roomParticipants.find(
                  (participant: RoomParticipant) => participant.user.userId === userId,
                );

              addedParticipants.push({
                id: existingParticipant?.id || userId,
                createdAt: existingParticipant?.createdAt || new Date().toISOString(),
                deletedAt: existingParticipant?.deletedAt || null,
                userRole: existingParticipant?.userRole || 'member',
                user: userData,
              });
            }
          }

          dispatch(
            addRoomParticipants({
              roomId: Number(roomId),
              participants: addedParticipants,
            }),
          );
          console.log(response);
        } else {
          dispatch(setError('Failed to parse response when adding participants to room'));
        }
        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error('Error in adding participants to room:', error);
      dispatch(setError('Failed to add participants to room'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const removeParticipants = createAsyncThunk<
  void,
  RemoveParticipantsArgs,
  { state: RootState }
>('room/removeParticipants', async ({ socket, payload }, { dispatch }) => {
  try {
    dispatch(setLoading(true));

    socket.emit('removeParticipats', payload);

    socket.once('removeParticipant', (response: { removedParticipant: RoomParticipant[] }) => {
      if (response.removedParticipant) {
        dispatch(
          removeRoomParticipants({
            roomId: payload.roomId,
            removedParticipants: response.removedParticipant,
          }),
        );
        console.log(`Users removed from Room with id: ${payload.participantIds}`);
      } else {
        dispatch(setError('Failed to remove participants from room'));
      }
      dispatch(setLoading(false));
    });
  } catch (error) {
    console.error('Error in removing participants from room:', error);
    dispatch(setError('Failed to remove participants from room'));
    dispatch(setLoading(false));
    throw error;
  }
});
