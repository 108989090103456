/* eslint-disable react/prop-types */
import { Avatar, Box } from '@mui/material';
import Setting from './Setting';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import AudioCall from './AudioCall';
import VideoCall from './VideoCall';
import ScreenShare from './ScreenShare';
import isMobile from '../../hooks/isMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { RoomData } from '../../store/constants/constants';
import { useContext } from 'react';
import { UserDataContext } from '../../routes/Main';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

interface ChatBoxHeadingProps {
  chat: RoomData | null;
  onBackClick: () => void;
}

const ChatBoxHeading: React.FC<ChatBoxHeadingProps> = ({ chat, onBackClick }) => {
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);

  return (
    <>
      <Box
        width='100%'
        borderRadius={mobileView?'0':'15px'}
        height={mobileView ? '50px' : '25px'}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box display='flex' sx={{ paddingLeft: '6px' }}>
          {mobileView &&
          <ArrowBackIosIcon onClick={onBackClick}/>}
          <span style={{ fontSize: '16px', fontWeight: '600' }}>{ chat?.roomType === "group" ? chat?.roomName : chat?.roomParticipants?.find((participant) => participant.user.userId !== userData.data.id)?.user?.userName || "Unknown User" }</span>
        </Box>

        {chat?.roomType === "group" ? (
          <Avatar
            src={
              chat?.avatar ||
              `https://randomuser.me/api/portraits/lego/${(chat?.id ?? Math.floor(Math.random() * 10)) % 10}.jpg`
            }
            alt="dtvu"
            sx={{
              alignSelf: "center",
              width: "52px",
              height: "52px",
              marginTop: "30px",
              boxShadow: "0 0 4px 4px #fff, 0 0 4px 4px #fff",
            }}
          />
        ) : (
          <StyledBadge
            sx={{
              "& .MuiBadge-badge": {
                color: chat?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id
                )?.user?.isActive
                  ? "#28a745"
                  : "#000",
                backgroundColor: chat?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id
                )?.user?.isActive
                  ? "#28a745"
                  : "#000",
                width: "10px",
                height: "10px",
                borderRadius: "5px",
              },
              zIndex: "99",
            }}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar
              src={
                chat?.avatar ||
                `https://randomuser.me/api/portraits/lego/${(chat?.id ?? Math.floor(Math.random() * 10)) % 10}.jpg`
              }
              alt="dtvu"
              sx={{
                alignSelf: "center",
                width: "52px",
                height: "52px",
                marginTop: "30px",
                boxShadow: "0 0 4px 4px #fff, 0 0 4px 4px #fff",
              }}
            />
          </StyledBadge>
        )}

        <Box display='flex' gap='15px'>
          <ScreenShare />
          <AudioCall />
          <VideoCall />
          <Setting />
        </Box>
      </Box>
    </>
  );
};

export default ChatBoxHeading;
