import { useContext, useEffect, useState } from 'react';
import {
  Box,
  TextField,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import { CustomTabPanel } from '../../components/Attendance/Report';
import isMobile from '../../hooks/isMobile';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { fetchUsers } from '../../store/user/userActions';
import CreateGroupDialog from './CreateGroupDialog';
import CreateGuestDialog from './CreateGuestDialog';
import {
  fetchRoomsByUser,
} from '../../store/RoomByID/roomAction';
import { UserDataContext } from '../../routes/Main';
import { RoomData } from '../../store/constants/constants';
import ChatItem from './ChatItem';
import { debounce } from 'lodash';

interface ChatListProps {
  onChatItemClick: (chat: RoomData) => void;
}

const ChatList: React.FC<ChatListProps> = (props: ChatListProps) => {
  const mobileView = isMobile();

  // Group add dialogue
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
  // const [searchQuery, setSearchQuery] = useState('');

  const handleOpenCreateGroupDialog = () => {
    setCreateGroupDialogOpen(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialogOpen(false);
  };

  // Guest add dialogue
  const [createGuestDialogOpen, setCreateGuestDialogOpen] = useState(false);
  // const handleOpenCreateGuestDialog = () => {
  //   setCreateGuestDialogOpen(true);
  // };
  const handleCloseCreateGuestDialog = () => {
    setCreateGuestDialogOpen(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  // Retrieving multiple users
  // const users = useSelector((state: RootState) => state.user.users);

  useEffect(() => {
    if (socket) {
      dispatch(fetchUsers({ socket }));
    }
  }, [dispatch, socket]);

  const { userData } = useContext(UserDataContext);

  // ADD PARTICIPANTS TO ROOM
  // const hasRun = useRef(false);
  // useEffect(() => {
  //   if (socket && !hasRun.current) {
  //     dispatch(
  //       addParticipants({
  //         socket: socket,
  //         payload: {
  //           roomId: 16,
  //           participantIds: [4,5],
  //         },
  //       }),
  //     );
  //     hasRun.current = true;
  //   }
  // }, [dispatch, socket]);

  // REMOVE USERS FROM ROOM
  // useEffect(() => {
  //   if (socket) {
  //     dispatch(
  //       removeParticipants({
  //         socket: socket,
  //         payload: {
  //           roomId: 16,
  //           participantIds: [3331, 3972],
  //         },
  //       }),
  //     );
  //   }
  // }, [dispatch, socket]);

  // Fetch rooms by userId (Assuming a static userId for now)
  const rooms = useSelector((state: RootState) => state.room.rooms);

  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData.data.id }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket, rooms]);

  const flattenedRooms = rooms.flat();

  const membersRooms = flattenedRooms.filter((room) => room.roomType === 'individual');
  const groupRooms = flattenedRooms.filter((room) => room.roomType === 'group');

  // Handle search input and update filtered users
  // const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const query = event.target.value.toLowerCase();
  //   setSearchQuery(query);

  //   if (query) {
  //     const filtered = users.filter((user) => user.userName.toLowerCase().includes(query));
  //     setFilteredUsers(filtered);
  //   } else {
  //     setFilteredUsers([]);
  //   }
  // };

  return (
    <>
      <Box
        maxHeight={mobileView ? '87vh' : '89vh'}
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >
        {/* Search Input */}
        <TextField
          placeholder='Search'
          variant='outlined'
          size='small'
          sx={{
            width: mobileView ? 'auto' : '100%',
            borderRadius: '15px',
            padding: mobileView ? '5px 20px' : '0',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search sx={{ color: 'action.active', pointerEvents: 'none' }} />
              </InputAdornment>
            ),
          }}
          // value={searchQuery}
          // onChange={handleSearchChange}
        />

        <Box sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              label='All'
              sx={{ width: '30%', color: value === 0 ? 'black' : 'lightgray' }}
              disableRipple
            />
            <Tab
              label='Members'
              sx={{ width: '30%', color: value === 1 ? 'black' : 'lightgray' }}
              disableRipple
            />
            <Tab
              label='Groups'
              sx={{ width: '30%', color: value === 2 ? 'black' : 'lightgray' }}
              disableRipple
            />
          </Tabs>
        </Box>

        {/* All chat list */}
        <CustomTabPanel value={value} index={0}>
          <List>
            {flattenedRooms?.slice(0, 20).map((room: RoomData) => {
              return (
                <ChatItem
                  key={room.id}
                  name={room.roomType === "group" ? room.roomName : room.roomParticipants?.find((participant) => participant.user.userId !== userData.data.id)?.user?.userName || "Unknown User"}
                  avatar={room.roomType === "group" ? room.avatar || `https://randomuser.me/api/portraits/lego/${(room.id ?? Math.floor(Math.random() * 10)) % 10}.jpg` : room.roomParticipants?.find((participant) => participant.user.userId !== userData.data.id)?.user?.profilePictureURL || `https://randomuser.me/api/portraits/lego/${(room.id ?? Math.floor(Math.random() * 10)) % 10}.jpg`}
                  lastmessage={room.lastMessage?.content || "Image"}
                  time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                  count={room.unseenMessagesCount}
                  unseen={room.unseenMessagesCount > 0 ? true : false}
                  online={room.roomParticipants?.find((participant) => participant.user.userId !== userData.data.id)?.user?.isActive}
                  onClick={() => props.onChatItemClick(room)}
                />
              );
            })}
          </List>
        </CustomTabPanel>

        {/* member chat list */}
        <CustomTabPanel value={value} index={1}>
          <List>
            {membersRooms?.slice(0, 20).map((room: RoomData) => {
              return (
                <ChatItem
                  key={room.id}
                  name={
                    room.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.userName || 'Unknown User'
                  }
                  avatar={
                    room.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.profilePictureURL ||
                    `https://randomuser.me/api/portraits/lego/${
                      (room.id ?? Math.floor(Math.random() * 10)) % 10
                    }.jpg`
                  }
                  lastmessage={room.lastMessage?.content || 'Image'}
                  time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                    'en-US',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    },
                  )}
                  count={room.unseenMessagesCount}
                  unseen={room.unseenMessagesCount > 0 ? true : false}
                  online={
                    room.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.isActive
                  }
                  onClick={() => props.onChatItemClick(room)}
                />
              );
            })}
          </List>
        </CustomTabPanel>

        {/* groups chat list */}
        <CustomTabPanel value={value} index={2}>
          <List>
            <ListItemButton
              onClick={handleOpenCreateGroupDialog}
              sx={{
                borderBottom: '2px solid lightgray',
                '&:hover': {
                  backgroundColor: 'grey.300',
                },
              }}
            >
              <ListItemIcon>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary='Create Group' />
            </ListItemButton>
            {groupRooms?.slice(0, 20).map((room: RoomData) => {
              return (
                <ChatItem
                  key={room.id}
                  name={room.roomName || 'Group Name'}
                  avatar={room.avatar || 'No avatar'}
                  lastmessage={room.lastMessage?.content || 'Image'}
                  time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                    'en-US',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    },
                  )}
                  count={room.unseenMessagesCount}
                  unseen={room.unseenMessagesCount > 0 ? true : false}
                  onClick={() => props.onChatItemClick(room)}
                />
              );
            })}
          </List>
        </CustomTabPanel>

        {/* Search Results Section (Shown Below Members) */}
        {/* {searchQuery && (
          <Box>
            <Typography variant='subtitle1' sx={{ marginLeft: 2, marginTop: 1 }}>
              New Members
            </Typography>
            <List>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <ListItem
                    key={user.userId}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (userData?.data?.id && user?.userId && socket) {
                        dispatch(
                          createRoom({
                            socket,
                            participantIds: [userData.data.id, user.userId],
                            roomType: 'individual',
                          }),
                        );
                      }
                    }}
                  >
                    <Avatar
                      src={
                        user?.profilePictureURL ||
                        `https://randomuser.me/api/portraits/lego/${
                          (user.userId ?? Math.floor(Math.random() * 10)) % 10
                        }.jpg`
                      }
                    />
                    <ListItemText primary={user.userName} />
                  </ListItem>
                ))
              ) : (
                <Typography variant='body2' sx={{ marginLeft: 2 }}>
                  No users found
                </Typography>
              )}
            </List>
          </Box>
        )} */}
      </Box>
      <CreateGroupDialog open={createGroupDialogOpen} onClose={handleCloseCreateGroupDialog} />
      <CreateGuestDialog open={createGuestDialogOpen} onClose={handleCloseCreateGuestDialog} />
    </>
  );
};

ChatList.propTypes = {
  onChatItemClick: PropTypes.func.isRequired,
};

export default ChatList;
