import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageData, PinnedMessageData } from '../constants/constants';

interface MessageState {
  messages: MessageData[];
  total: number;
  page: number;
  limit: number;
  selectedMessage: MessageData | null;
  pinMessage: PinnedMessageData | null;
}

interface AttachmentData {
  base64String: string;
  fileName: string;
  extension: string;
  roomId: number;
}

const initialState: MessageState = {
  messages: [],
  total: 0,
  page: 1,
  limit: 10,
  selectedMessage: null,
  pinMessage: null,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    sendMessage(state, action: PayloadAction<MessageData>) {
      state.messages.push(action.payload);
    },
    getMessage(
      state,
      action: PayloadAction<{
        data: MessageData[];
        total: number;
        page: number;
        limit: number;
        pinMessage: PinnedMessageData | null;
      }>,
    ) {
      state.messages = action.payload.data;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.pinMessage = action.payload.pinMessage;
    },
    replyMessage(state, action: PayloadAction<MessageData>) {
      state.messages.push(action.payload);
    },
    deleteMessage(state, action: PayloadAction<number>) {
      const message = state.messages.find((msg) => msg.id === action.payload);
      console.log(message);
    },
    getSeenMessage(state, action: PayloadAction<number>) {
      const message = state.messages.find((msg) => msg.id === action.payload);
      console.log(message);
    },
    setSelectedMessage(state, action: PayloadAction<MessageData | null>) {
      // New action
      state.selectedMessage = action.payload;
    },
    sendAttachment(state, action: PayloadAction<AttachmentData>) {
      console.log('Attachment sent:', action.payload);
    },
    pinMessage(state, action: PayloadAction<PinnedMessageData>) {
      state.pinMessage = action.payload;
    },
    removePinMessage(state) {
      state.pinMessage = null;
    },
  },
});

export const {
  sendMessage,
  getMessage,
  replyMessage,
  deleteMessage,
  getSeenMessage,
  setSelectedMessage,
  sendAttachment,
  removePinMessage,
  pinMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
