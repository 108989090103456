import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  sendMessage,
  getMessage,
  replyMessage,
  deleteMessage,
  getSeenMessage,
  sendAttachment,
  pinMessage,
  removePinMessage,
} from './messageSlice';
import { MessageData, PinnedMessageData } from '../constants/constants';
import { Socket } from 'socket.io-client';

interface SendMessageArgs {
  socket: Socket;
  message: {
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface ReplyMessageArgs {
  socket: Socket;
  reply: {
    messageId: number;
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface DeleteMessageArgs {
  socket: Socket;
  messageId: number;
}

interface MessageSeenArgs {
  socket: Socket;
  seen: {
    messageId: number;
    userId: number;
  };
}

interface GetMessageArgs {
  message1: {
    roomId?: number;
    userId: number;
    total?: number;
    page?: number;
    limit?: number;
  };
  socket: Socket;
}

interface PinMessageArgs {
  socket: Socket;
  pinData: {
    roomId: number;
    messageId: number;
  };
}

interface RemovePinMessageArgs {
  socket: Socket;
  pinnedMessageId: number;
}

interface SendAttachmentArgs {
  socket: Socket;
  attachment: {
    base64String: string;
    fileName: string;
    extension: string;
    roomId: number;
  };
}

export const pinMessageAction = createAsyncThunk(
  'message/pinMessage',
  async ({ socket, pinData }: PinMessageArgs, { dispatch }) => {
    try {
      socket.emit('pinMessage', pinData);

      socket.on('pinMessage', (data: PinnedMessageData) => {
        dispatch(pinMessage(data));
      });
    } catch (error) {
      console.error('Error in pinning message:', error);
      throw error;
    }
  },
);

export const removePinMessageAction = createAsyncThunk(
  'message/removePinMessage',
  async ({ socket, pinnedMessageId }: RemovePinMessageArgs, { dispatch }) => {
    try {
      socket.emit('removePinMessage', { id: pinnedMessageId });
      socket.on('removePinMessage', () => {
        dispatch(removePinMessage());
      });
    } catch (error) {
      console.error('Error in removing pinned message:', error);
      throw error;
    }
  },
);

export const sendMessageAction = createAsyncThunk(
  'message/sendMessage',
  async ({ socket, message }: SendMessageArgs, { dispatch }) => {
    try {
      socket.emit('textMessage', message);
      dispatch(sendMessage(message));
    } catch (error) {
      console.error('Error in sending message:', error);
      throw error;
    }
  },
);

export const replyMessageAction = createAsyncThunk(
  'message/replyMessage',
  async ({ socket, reply }: ReplyMessageArgs, { dispatch }) => {
    try {
      socket.emit('replyMessage', reply);
      dispatch(replyMessage(reply));
    } catch (error) {
      console.error('Error in replying to message:', error);
      throw error;
    }
  },
);

export const deleteMessageAction = createAsyncThunk(
  'message/deleteMessage',
  async ({ socket, messageId }: DeleteMessageArgs, { dispatch }) => {
    try {
      socket.emit('deleteMessage', messageId);
      dispatch(deleteMessage(messageId));
    } catch (error) {
      console.error('Error in deleting message:', error);
      throw error;
    }
  },
);

export const messageSeenAction = createAsyncThunk(
  'message/getSeenMessage',
  async ({ socket, seen }: MessageSeenArgs, { dispatch }) => {
    try {
      socket.emit('getSeenMessage', seen);
      dispatch(getSeenMessage(seen.messageId));
    } catch (error) {
      console.error('Error in marking message as seen:', error);
      throw error;
    }
  },
);

export const getMessageAction = createAsyncThunk(
  'message/getMessage',
  async ({ socket, message1 }: GetMessageArgs, { dispatch }) => {
    try {
      socket.emit('getMessage', message1);
      socket.on(
        'message',
        (data: {
          data: MessageData[];
          total: number;
          page: number;
          limit: number;
          pinMessage: PinnedMessageData | null;
        }) => {
          dispatch(getMessage(data));
        },
      );
    } catch (error) {
      console.error('Error in fetching messages:', error);
      throw error;
    }
  },
);

export const sendAttachmentAction = createAsyncThunk(
  'message/sendAttachment',
  async ({ socket, attachment }: SendAttachmentArgs, { dispatch }) => {
    try {
      socket.emit('attachment', attachment);
      dispatch(sendAttachment(attachment));
    } catch (error) {
      console.error('Error in sending attachment:', error);
      throw error;
    }
  },
);
